import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col, Modal } from "react-bootstrap";
import { Layout } from "../components/Layout";
import { Link, navigate } from "gatsby";
import ProductBanner from "../components/product_banners/product_banners";
import Button from '../components/buttons/button'
import Banner from "../imgs/bg_resultadosbusqueda.jpg";
import Image from "../components/image/image";

import "./busqueda.css";
import {apiRequest} from "../apiRquest/apiRequest";
import Loading from "../components/loading/loading";

const pageTitle = 'Resultados de la búsqueda | Balanz';
const pageDesc = 'Este son los resultados que hemos encontrado en relación a tu búsqueda.';

const ResultadosPage = () => {
    const [searchSteadyInput, setSteadySearchInput] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [loadingResults, setLoadingResults] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [search, setSearch] = useState("undefined");
    const steadyInputRef = useRef(null);


    useEffect(() => {
        let anchorbuscador = document.querySelector(".bannerBuscador");
        anchorbuscador.scrollIntoView();

        let param = new URLSearchParams(window.location.search).get('q');
        if(param && param !=''){
            setSearch(param)
        }else{
            setSearch(null)
        }
       /* const queryParams = new URLSearchParams(window.location.search);
        setSearch(queryParams.get('q'))*/

        if(param && param !=="undefined" && param !==""){
            setLoadingResults(true);
            apiRequest
                .post("/v1/search",
                    {s: param})
                .then((res) => {
                    let dataSet = [];
                    res.data.result.forEach((element) => {
                        dataSet.push({title: element.title, meta: element.meta, category:element.category, link:element.link});
                    });
                    setSearchResults(dataSet);
                })
                .catch((err) => {
                    console.log(err);
                }).finally(() => {
                setLoadingResults(false);
            })
        }
    },[])

    const handleSubmitSteadySearch = (event) => {
        event.preventDefault();
        if(searchSteadyInput.length > 3){
            setErrorMessage('')
            //navigate(`/buscar/?q=${searchSteadyInput}`);
            window.location.href = `/buscar/?q=${searchSteadyInput}`;

        }else{
            setErrorMessage('Por favor ingrese una palabra de al menos 4 caracteres para realizar la búsquedaa');
            //alert(`No se ingresaron datos a buscar`)
        }
    }

    let searchresults = [
        {
            title:"CEDEARs",
            meta: "Es un acuerdo estandarizado en cantidad y calidad para comprar o vender un determinado activo en un momento específico fijando precios futuros. Se negocian en mercados institucionalizados y son tanto autorizados como regulados por una autoridad de contralor (CNV).",
            category: "Balanz University"
        },
        {
            title:"Futuros",
            meta: "The investment objective of this fund is to achieve capital appreciation through investment in a portfolio of fixed income assets linked to the evolution of the official peso-dollar exchange rate. Mainly composed of a currency-hedged diversified portfolio of fixed income assets, combining dollar-linked securities and synthetics.",
            category: "Inversiones"
        }
    ];

    const page = (
        <>
            <ProductBanner
                bannerContainerClassName="bannerBuscador"
                bannerTrasparecy=""
                productTitle="Buscador"
                bannerImage={Banner}
                bannerImageClassName="buscadorBannerImage"
                textContainerClassName="textContainer"
                bannerText={false}
                bannerTextClassName="bannerText3"
                bannerImageMobile={Banner}
                mobile={false}
            />
            { (search && search !=="undefined" && search !=="") ?
                (
                loadingResults ?
                 (
                 <section id className="fullwidth resultados-busqueda " style={{"minHeight":"25em"}}>
                     <Container>
                         <Row className="pb-5 pb-sm-5 pb-md-4 pb-lg-5">
                             <Col xs={12} >
                                <Loading customClass="loading-searchresults"/>
                             </Col>
                         </Row>
                     </Container>
                 </section>
                 ):(
                <section className={`fullwidth pt-0 resultados-busqueda`} style={{"minHeight":"25em"}}>
                   <div className="pb-5 pb-sm-5 pb-md-4 pb-lg-5">
                   {/* <Container>*/}
                       {/* <Row className="pb-5 pb-sm-5 pb-md-4 pb-lg-5">
                            <Col xs={12} >*/}
                            {(searchResults.length > 0) ?
                            (
                            <>
                            <div className="bg-lt-grey pt-2 pt-sm-3 pt-md-4 pb-3 pb-sm-4 pb-md-5">
                                <Container>
                                    <Row>
                                        <Col xs={12} >
                                            <h2 className="title-resultados">Resultados de la búsqueda: {search && <span>{search}</span>}</h2>
                                            <p className="intro-text base-text mb-2">Se han encontrado {searchResults.length} resultados</p>
                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                            <Container>
                                <Row className="pt-3 pt-sm-4 pt-md-5">
                                    <Col xs={12} >
                                    { searchResults.map((item, key) => {
                                        return(
                                        <div className="result-item pb-3 pt-4">
                                            <a className="result-tag">{item.category}</a>
                                            <h3 className="result-title ash2 my-2">
                                                <Link to={item.link}>
                                                    {item.title}
                                                </Link>
                                            </h3>
                                            <Link to={item.link}>
                                            <p className="intro-text base-text pb-2 pt-1">{item.meta}
                                            </p>
                                            </Link>

                                        </div>
                                        )
                                    })}
                                    </Col>
                                </Row>
                            </Container>
                            </>
                            ):(
                                <>
                                <div className="bg-lt-grey pt-2 pt-sm-3 pt-md-4 pb-3 pb-sm-4 pb-md-5">
                                <Container>
                                    <Row>
                                        <Col xs={12} >
                                            <h2 className="title-resultados">No se han encontrado resultados para la búsqueda: {search && <span>{search}</span>}</h2>
                                        </Col>
                                    </Row>
                                </Container>
                                </div>
                                </>
                            )}
                               <Container>
                                   <Row className="pt-3 pt-sm-4 pt-md-5">
                                       <Col xs={12} >
                                            <p className="intro-text base-text pt-3">Realizar otra búsqueda</p>
                                            <form action="" onSubmit={handleSubmitSteadySearch} className="steady-search-box">
                                                <div className="pt-2 pt-sm-3 pb-2 h-100 d-flex">
                                                    <input ref={steadyInputRef} value={searchSteadyInput} type="text"
                                                           onChange={e => setSteadySearchInput(e.target.value)}
                                                           className="py-2 px-3 base-text steady-search-input me-1 me-sm-2 me-md-3 mt-1 mb-1"
                                                           placeholder="Buscar..."/>
                                                    <Button variant="secondary btn-sm buscar-btn" text="Buscar"
                                                            onClick={handleSubmitSteadySearch}/>
                                                    <div style={{
                                                        "maxWidth": "300px",
                                                        "marginTop": "0.25rem",
                                                        "fontSize": ".875em",
                                                        "color": "#dc3545"
                                                    }}>{errorMessage}</div>
                                                </div>

                                            </form>
                                       </Col>
                                   </Row>
                               </Container>
                            {/*</Col>
                        </Row>*/}
                   {/* </Container>*/}
                   </div>
                </section>
                )
                ):(
                <section className="fullwidth form-busqueda" style={{"minHeight":"25em"}}>
                    {search == null && (
                    <Container>
                        <Row className="pb-5 pb-sm-5 pb-md-4 pb-lg-5">
                            <Col xs={12}>
                                <h2 className="title-resultados">Encontrá lo que estabas buscando</h2>
                                <p className="intro-text base-text">Buscar palabras clave en los contenidos de este
                                    sitio web</p>

                                <form action="" onSubmit={handleSubmitSteadySearch} className="steady-search-box">
                                    <div className="py-5 h-100">
                                        <input ref={steadyInputRef} value={searchSteadyInput} type="text"
                                               onChange={e => setSteadySearchInput(e.target.value)}
                                               className="py-2 px-3 base-text steady-search-input me-3"
                                               placeholder="Buscar..."/>
                                        <Button variant="secondary btn-sm buscar-btn" text="Buscar"
                                                onClick={handleSubmitSteadySearch}/>
                                        <div style={{
                                            "maxWidth": "300px",
                                            "marginTop": "0.25rem",
                                            "fontSize": ".875em",
                                            "color": "#dc3545"
                                        }}>{errorMessage}</div>
                                    </div>

                                </form>
                            </Col>
                        </Row>
                    </Container>
                    )}
                </section>
            ) }

            <section className="fullwidthbg appdownload">
                <Container>
                    <Row className="d-flex align-items-stretch">
                        <Col>
                            <div className="section-content">
                                <p className="volanta">Descargá la app</p>
                                <h3 className="titulo" >Invertí desde <br/>tu celular</h3>
                                <p className="texto">Podés abrir tu cuenta, ver las <br/> cotizaciones en tiempo real e invertir <br/> desde cualquier lugar</p>
                                <div className="cta">
                                    <a href="https://play.google.com/store/apps/details?id=com.mobile.balanz.droid&utm_source=Website&utm_medium=Banner&utm_campaign=Android" target="_blank">
                                        <Image
                                            className="store-img"
                                            src="PlayStore.png"
                                            alt="GStore"
                                        /></a>
                                    <a href="https://apps.apple.com/us/app/balanz/id1318206099?utm_source=Website&utm_medium=Banner&utm_campaign=IOS" target="_blank">
                                        <Image
                                            className="store-img"
                                            src="AppStore.png"
                                            alt="AStore"
                                        />
                                    </a>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );

    return (
        <main>
            <Layout title={pageTitle} description={pageDesc} childrem={page} category={"buscador"}></Layout>
        </main>
    );
};


// Step 3: Export your component
export default ResultadosPage;